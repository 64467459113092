import React, { useEffect, useLayoutEffect, useState } from 'react';
import '../../assets/css/play-game.css';
import Loader from "../includes/Loader";
import { useParams } from 'react-router-dom';
import h5GamesJsonData from "../data/h-five-games.json";

function PlayGames() {
    const [loading, setLoading] = useState(true);
    const [gameUrl, setGameUrl] = useState(true);
    const { gameIndex } = useParams();


    useLayoutEffect(() => {
        document.title = 'Play | Ufone ESG';
        const footer = document.getElementById('footer');
        footer.style.position = 'absolute';
        footer.style.zIndex = '-1';
    }, []);

    useEffect(() => {

        setLoading(false);
        const gameData = Object.values(h5GamesJsonData);
        const gameUrlFromData = gameData[gameIndex] ? gameData[gameIndex].url : '';
        setGameUrl(gameUrlFromData);
    }, []);


    return (
        <div className="iframe-container">
            {loading ? (
                <Loader />
            ) : (
                <iframe
                    src={gameUrl}
                    title="Game"
                    frameBorder="0"
                    allowFullScreen
                    loading="lazy"
                />
            )}
        </div>
    );
}

export default PlayGames;