import React, { useEffect, useState } from 'react';
import '../../assets/css/main-slider.css';
import banner1 from '../../assets/images/banner/slider1.jpg';
import banner2 from '../../assets/images/banner/slider2.jpg';
import banner3 from '../../assets/images/banner/slider3.jpg';
import banner4 from '../../assets/images/banner/slider4.jpg';
import Slider from 'react-slick';
import Loader from "./Loader";

const HomeSlider = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
    };

    return (
        <section>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="banner-view">
                        <div className="banner-text">
                            <p className="banner-heading">Play Live Tournaments</p>
                        </div>
                        <Slider {...settings} className="slider slider-mob">
                            <img className="slider-img" src={banner1} alt="Banner-1" />
                            <img className="slider-img" src={banner2} alt="Banner-2" />
                            <img className="slider-img" src={banner3} alt="Banner-3" />
                            <img className="slider-img" src={banner4} alt="Banner-4" />
                        </Slider>
                    </div>
                    {/* <div className="banner-text-mobile"> */}
                    {/* <p className="banner-heading-mobile">Compete in Tournament</p> */}
                    {/* </div> */}
                </>
            )}
        </section>
    );
}

export default HomeSlider;