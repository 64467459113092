import React from 'react';
import { PropagateLoader } from 'react-spinners';
import '../../assets/css/loader.css';

const Loader = () => {
    return (
        <div className="loader-container">
            <PropagateLoader color={'#8cb517'} loading={true} />
        </div>
    );
};

export default Loader;
