import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, useParams } from "react-router-dom";
import '../../assets/css/tournament-details.css';
import Loader from "../includes/Loader";
import { requestPostHTTP } from "../helpers/helper";
import { isLogin } from "../auth/AuthController";
import { useSelector } from "react-redux";
import rewardsImg from "../../assets/images/icons/rewards.png";
import videoIcon from "../../assets/images/icons/video.png";
import Modal from "../pusher/Modal";
import VideoModal from "../modals/VideoModal";
// import { handleDiscordLogin } from "../auth/DiscordAuth"
import { tournamentEntry } from '../api_calls/tournaments';

const TournamentDetails = () => {
    const [loading, setLoading] = useState(true);
    const [tournamentDetail, setTournamentDetail] = useState(true);
    const { tournamentId } = useParams();
    const modal = useSelector((state) => state.openSubscribedModal);
    const [tournamentCheckIn, setTournamentCheckIn] = useState([]);
    const pubgId = useRef(null);
    const pubgNick = useRef(null);
    const userEmailRef = useRef(null);
    const [pubgValidation, setPubgValidation] = useState(false);
    const [emailValidation, setEmailValidation] = useState(false);
    const [notFoundTournament, setNotFoundTournament] = useState(false);
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const [otherErrors, setOtherErrors] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [makeEntryLoading, setMakeEntryLoading] = useState(false);
    let navigate = useNavigate();
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    useEffect(() => {
        async function fetchTournamentDetails() {
            try {
                const tournament = await requestPostHTTP('/v1/tournament/view', { id: tournamentId });
                if (tournament.data.length > 0) {
                    setTournamentDetail(tournament.data[0]);
                } else {
                    setNotFoundTournament(true);
                }
            } catch (error) {
                console.error('Error fetching tournament data:', error);
                setNotFoundTournament(true);
            } finally {
                setLoading(false);
            }
        }

        fetchTournamentDetails();
    }, []);

    function openVideoModal() {
        setIsVideoModalOpen(true);
    }


    function funTournamentCheckIn(event, tourn_id) {
        if (makeEntryLoading) { return };
        setMakeEntryLoading(true);
        if (!isLogin()) {
            // Redirect to login page if not logged in
            window.location.href = process.env.REACT_APP_LOGIN_URL;
            return; // Ensure the function exits after redirect
        }

        try {
            setPubgValidation(false);
            setEmailValidation(false);
            let params = { id: tourn_id };

            // Check if the tournament type is "Cash" and the email field is empty
            if (tournamentDetail.tourn_type === "Cash" && (!validateEmail(userEmailRef.current.value) || !userEmailRef.current.value)) {
                setMakeEntryLoading(false);
                setEmailValidation(true);
                setTimeout(() => {
                    setEmailValidation(false);
                }, 5000);
                return;
            } else if (tournamentDetail.tourn_type === "Cash" && (validateEmail(userEmailRef.current.value) && userEmailRef.current.value)) {

                // Assign email to params if it's not already handled
                params.userEmail = userEmailRef.current.value;
            }


            // Check if the tournament name matches specific games
            if (["Freefire", "Freefire Rampage", "PUBG", "PUBG Mobile", "Call of Duty", "Call of Duty Mobile"].includes(tournamentDetail.tourn_name)) {
                if (!pubgId.current.value.trim() || !pubgNick.current.value.trim()) {
                    setMakeEntryLoading(false);
                    setPubgValidation(true);
                    return;
                }
                params.pubg_id = pubgId.current.value.trim();
                params.pubg_nick = pubgNick.current.value.trim();
            }

            // Make the API request
            // requestPostHTTP('/v1/tournament/entry/checkin', params)

            tournamentEntry(params).then(response => {
                if (response?.data?.data?.already_submited) {
                    // Handle the case where the user has already submitted
                    setTournamentCheckIn('You have already Submitted. Please wait for the upcoming message with further instructions on how to join. Thank you for your participation and patience');
                    setTimeout(() => {
                        navigate("/tournaments");
                    }, 7000);
                } else if (response?.data?.data?.length > 0) {
                    // Handle the successful submission case
                    setTournamentCheckIn("Congratulations! You've successfully entered in the tournament. Please wait for the upcoming message with further instructions on how to join. Thank you for your participation and patience.");
                    setTimeout(() => {
                        navigate("/tournaments");
                    }, 7000);
                }
                setShowSuccessModal(true);
                setTimeout(() => {
                    setShowSuccessModal(false);
                }, 7000);
            })
                .catch(error => {
                    setMakeEntryLoading(false);
                    if (error.response?.data?.data?.eCode === -2) {
                        setOtherErrors(true);
                        setErrMsg(error.response.data.data.message);
                        setTimeout(() => {
                            setOtherErrors(false);
                            setErrMsg("");
                        }, 5000);
                    }
                    if (error.response?.data?.data?.eCode === -1) {
                        setOtherErrors(true);
                        setErrMsg(error.response.data.data.message);
                        setTimeout(() => {
                            setOtherErrors(false);
                            setErrMsg("");
                        }, 5000);
                    }
                    if (error.response.data.message === "Unable to refresh token") {
                        window.location.href = process.env.REACT_APP_LOGIN_URL;
                        return; // Ensure the function exits after redirect
                    }

                })
                .finally(() => {
                    setMakeEntryLoading(false);
                });

        } catch (error) {
            console.error('Unexpected error during tournament check-in:', error);
            setMakeEntryLoading(false);
        }
    }

    function validateEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const result = regex.test(email);
        return result;
    }

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="container-fluid px-3">
                        <div className="row">
                            <div className="col-md-12">
                                <img className="img-fluid tournament-detail-banner-img" src={tournamentDetail.banner}
                                    alt="banner-tournament" />
                            </div>

                            {/*<div className="play-now-card-slider-btn">
                                <NavLink className="play-btn w-160"
                                         onClick={handleDiscordLogin}>
                                    Login with Discord
                                </NavLink>
                            </div>*/}

                            <div className="mt-5">
                                <div className="col-md-12">
                                    {tournamentDetail.tourn_name === 'Call of Duty' && (
                                        <p className="detail mb-4 video-link-wrapper">
                                            <a href="javaScript:void(0)" className="watch-video-link"
                                                onClick={() => openVideoModal()}><img className="video-icon"
                                                    src={videoIcon} alt="" />Watch Video</a>
                                        </p>
                                    )}
                                    <p className="detail mb-4">
                                        <span>Tournament Name: </span>
                                        {tournamentDetail.tourn_name}
                                    </p>
                                    {/* <p className="detail mb-4">
                                        <span>1st Prize: </span>
                                        {tournamentDetail.prize} {tournamentDetail.tourn_type}
                                    </p>
                                    <p className="detail mb-4">
                                        <span>2nd Prize: </span>
                                        {tournamentDetail.second_prize} {tournamentDetail.tourn_type}
                                    </p>
                                    <p className="detail mb-4">
                                        <span>3rd Prize: </span>
                                        {tournamentDetail.third_prize} {tournamentDetail.tourn_type}
                                    </p> */}
                                    <p className="detail mb-4">
                                        <span>Prize: </span>
                                        {tournamentDetail.prize}
                                    </p>
                                    <p className="detail mb-4">
                                        <span>Platform: </span>
                                        {tournamentDetail.platform}
                                    </p>
                                    {tournamentDetail.isEntryFree === 1 ?
                                        <p className="detail mb-4">
                                            <span>Entry Fee: </span>
                                            {tournamentDetail.entryFee} Credits
                                        </p>
                                        :
                                        <p className="detail mb-4">
                                            <span>Entry Fee: </span>
                                            Enter from Free!
                                        </p>}
                                    <p className="detail mb-4">
                                        <span>Starting Time: </span>
                                        {tournamentDetail.timeStart}
                                    </p>
                                    <p className="detail mb-4">
                                        <span>Ending Time: </span>
                                        {tournamentDetail.timeEnd}
                                    </p>
                                    {(tournamentDetail.tourn_name === 'Freefire Rampage' || tournamentDetail.tourn_name === 'PUBG Mobile' || tournamentDetail.tourn_name === 'Call of Duty' || tournamentDetail.status < 2) && (
                                        <div className="pubg_fields_wrapper">
                                            {/* Enter fields for PUBG*/}
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                                                <input
                                                    type="tel"
                                                    name='pubgId'
                                                    placeholder="Enter Your UID"
                                                    className="bio-detail-textarea"
                                                    ref={pubgId}
                                                    onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                                                    required
                                                    minLength={4}
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                                                <input
                                                    type="text"
                                                    name='pubgNick'
                                                    placeholder="Enter Your NICK"
                                                    className="bio-detail-textarea"
                                                    ref={pubgNick}
                                                    required
                                                    minLength={3}
                                                />
                                            </div>
                                            {tournamentDetail.tourn_type === "Cash" && (
                                                <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                                                    <input
                                                        type="email"
                                                        name='userEmail'
                                                        placeholder="Enter Your Email"
                                                        className="bio-detail-textarea"
                                                        ref={userEmailRef}
                                                        required
                                                        minLength={3}
                                                        maxLength={255}
                                                    />
                                                </div>
                                            )}
                                            <div className="col">
                                                {pubgValidation && (<p className="text-red mt-1">Entering UID and NICK is mandatory for registering. Please enter correct ID and NICK to make entry.</p>)}
                                                {emailValidation && (<p className="text-red mt-1">Entering email is mandatory for registering. Please enter correct email to make entry.</p>)}
                                                {otherErrors && (<p className="text-red mt-1">{errMsg}</p>)}

                                            </div>
                                        </div>
                                    )}
                                    <div className="play-now-card-slider-btn">
                                        {tournamentDetail.status == 1 ?
                                            <NavLink className="play-btn"
                                                onClick={(event) => funTournamentCheckIn(event, tournamentId)} style={{ width: "150px", fontSize: "16px" }}>
                                                {makeEntryLoading ? "Please wait" : "Register"}

                                            </NavLink>
                                            : tournamentDetail.status == 2 ?
                                                <p className="detail mb-4">
                                                    <span>Tournament is in progress</span>
                                                </p>
                                                : tournamentDetail.status > 2 ?
                                                    <p className="detail mb-4">
                                                        <span>Tournament has Ended</span>
                                                    </p> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {isVideoModalOpen && (
                        <VideoModal videoUrl={window.location.origin + '/videos/cod.mp4'} onClose={() => setIsVideoModalOpen(false)} />
                    )}

                    {/*Model*/}
                    {showSuccessModal &&
                        <div className="modal fade subscribe-modal" id="showSuccess" tabIndex="-1"
                            aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: "block", opacity: "1" }}>
                            <div className="modal-dialog subscribe-modal-dialog">
                                <div className="modal-content subscribe-modal-content">
                                    <div className="modal-body subscribe-modal-body">
                                        <div className="subscribe-modal-body-back-img notification-back-img">
                                            <img className="rewards-modal-icon" src={rewardsImg} alt="rewards-success" />
                                        </div>
                                        <h6 className="subscribe-info-heading-modal">Congratulations</h6>
                                        <p className="subscribe-heading-modal">{tournamentCheckIn}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            )}
        </>
    );
}

export default TournamentDetails;
