import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './assets/css/bootstrap.min.css';
import './assets/css/main.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import 'slick-carousel';
import Header from './containers/HeaderContainer';
import Footer from './components/includes/Footer';
import MobileNotification from './components/includes/MobileNotification.js';
import HomePage from './components/pages/HomePage';
import PlayGames from './components/pages/PlayGames';
import TournamentDetails from './components/pages/TournamentDetails';
import Profile from './components/pages/Profile';
import TournamentPage from './components/pages/TournamentPage';
// import RewardPage from './components/pages/RewardPage.js';
import RewardPage from './components/pages/redeem_page/RewardsPage.js';
import CompletedTaskPage from './components/pages/CompletedTaskPage';
import ChallengesPage from './components/pages/ChallengesPage';
import PrivateRoute from './components/auth/PrivateRoute';
import UnSubscribed from './components/pages/UnSubscribed';
import GameChallenges from "./components/detailPages/GameChallenges";
import SubmitEntry from "./components/detailPages/SubmitEntry";
import EntryDetails from "./components/detailPages/EntryDetails";
import ProtectedRoute from './components/auth/ProtectedRoute'
// import echo from './components/includes/EchoSetup';
// import Modal from "./components/pusher/Modal";
import DiscordAuthCallback from './components/auth/DiscordAuthCallback';
import Leaderboard from './components/pages/LeaderBoard.js';
import YouTubeVideosPage from './components/pages/YouTubeVideosPage.js';

function App() {
    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const [imageSrc, setImageSrc] = useState('');
    // const [pushNotificationId, setPushNotificationId] = useState('');
    // const [pushNotificationFireId, setPushNotificationFireId] = useState('');

    // useEffect(() => {
    //     const channel = echo.channel('ShowModalBoxChannel');
    //     channel.listen('.ShowModalBox', data => {
    //         let imgSrc = '';
    //         const windowWidth = window.innerWidth;
    //         if (windowWidth > 850) {
    //             imgSrc = data.eventData.desktop_img
    //         } else if (windowWidth <= 650) {
    //             imgSrc = data.eventData.mobile_img
    //         }

    //         setPushNotificationId(data.eventData.push_id);
    //         setPushNotificationFireId(data.eventData.push_fire_id);
    //         setImageSrc(imgSrc);
    //         setIsModalOpen(true);
    //         console.log('Received event:', imgSrc);
    //     });
    //     return () => {
    //         channel.stopListening('.ShowModalBox');
    //     };
    // }, []);

    return (
        <BrowserRouter>
            <Header />
            <div id="jazz-esl">
                <Routes>
                    <Route element={<PrivateRoute />}>
                        <Route index element={<HomePage />} />
                        <Route path="/auth-discord-callback" element={<DiscordAuthCallback />} />
                        <Route path="/challenges" element={<ChallengesPage />} />
                        <Route path="/rewards" element={<RewardPage />} />
                        <Route path="/tournaments" element={<TournamentPage />} />
                        <Route path="/game-challenges/:game_id" element={<GameChallenges />} />
                        <Route path="/play-games/:gameIndex" element={<PlayGames />} />
                        <Route path="/leaderboard" element={<Leaderboard />} />
                        <Route path="/tournament-highlights" element={<YouTubeVideosPage />} />
                        <Route element={<ProtectedRoute />}>
                            <Route path="/submit-entry/:game_id/:challenge_id/:entry_status" element={<SubmitEntry />} />
                            <Route path="/entry-details/:game_id/:challenge_id" element={<EntryDetails />} />
                            <Route path="/mob-profile/:editable?" element={<Profile />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/mob-notifications" element={<MobileNotification />} />
                            <Route path="/completed-task" element={<CompletedTaskPage />} />
                            <Route path="/un-subscribed" element={<UnSubscribed />} />
                            <Route path="/tournament-details/:tournamentId" element={<TournamentDetails />} />
                        </Route>
                    </Route>
                </Routes>
            </div>
            {/* {isModalOpen && (
                <Modal imageUrl={imageSrc} pushId={pushNotificationId} pushFireId={pushNotificationFireId} onClose={() => setIsModalOpen(false)} />
            )} */}
            <Footer />
        </BrowserRouter>
    );
}

export default App;
