import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import '../../assets/css/tournament.css';
import { requestGetHTTP, requestPostHTTP } from "../helpers/helper";
import Loader from "../includes/Loader";
import { isLogin } from "../auth/AuthController";
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import rewardsImg from "../../assets/images/icons/rewards.png";

function TournamentPage() {
    const [tournaments, setTournaments] = useState([]);
    const [notFoundTournament, setNotFoundTournament] = useState(false);
    const [tournamentCheckIn, setTournamentCheckIn] = useState([]);
    const [loading, setLoading] = useState(true);
    const modal = useSelector((state) => state.openSubscribedModal);
    const showSuccessModal = useRef(null);

    useLayoutEffect(() => {
        document.title = 'Tournaments | Ufone ESG'; // title
    }, [])
    useEffect(() => {
        async function fetchReqData() {
            try {

                const getTournaments = await requestGetHTTP('/v1/tournaments/all');
                if (getTournaments.data.length > 0) {
                    setTournaments(getTournaments.data);
                } else {
                    setNotFoundTournament(true);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        fetchReqData();

    }, []);

    /**
     *
     * @param tourn_id
     */
    function funTournamentCheckIn(event, tourn_id) {
        if (!isLogin()) {
            modal.subscribedModalRef.current.click();
        }
        try {
            requestPostHTTP('/v1/tournament/entry/checkin', { id: tourn_id }).
                then(response => {
                    if (response && response.data.already_submited === true) { // already exist
                        setTournamentCheckIn('You have already Submitted.');
                        showSuccessModal.current.click();
                    } else if (response && response.data.length > 0) {
                        setTournamentCheckIn('Congratulations! You\'ve successfully entered in the tournament. Please wait for the upcoming message with further instructions on how to join. Thank you for your participation and patience.');
                        showSuccessModal.current.click();
                    }
                });
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    /**
     *
     * @param event
     */
    const checkLogin = (event) => {
        if (!isLogin()) {
            event.preventDefault();
            modal.subscribedModalRef.current.click();
        }
    }

    return (
        <section>
            <div className="cards-wrapper-tournament tournament-wrapper col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="tournament-heading">
                    <h2 className="headingz mb-4">Tournaments</h2>
                    <p className="tournament-sub-heading">Play Weekly Tournaments and Earn Rewards</p>
                </div>

                <div className="row">
                    {loading ? (
                        <Loader />
                    ) : (
                        tournaments.length > 0 && tournaments.map((tournament, index) => (
                            <div key={index} className="col-12 col-sm-12 col-md-4 col-lg-4 tour-card-wrapper">
                                <NavLink onClick={(event) => checkLogin(event)} to={`/tournament-details/${tournament.tourn_id}`}>
                                    <div className="cards-tournament-round">
                                        <img src={tournament.thumbnail} alt="tournament-img" style={{ minHeight: "320px" }} />
                                        <div className="tournament-card-info">
                                            <div className="row">
                                                <div className="col-8">
                                                    <h6 className="tournament-info-heading">{tournament.tourn_name}</h6>
                                                    <p className="card-heading-tournament">Win {tournament.prize} Credits</p>
                                                    <p className="tournament-page-total-participant">Participant: {tournament.total_checkin}</p>
                                                </div>
                                                <div className="col-4 comming-soon-tournament">
                                                    <p>Starting at</p>
                                                    <p>{tournament.time.split(" ")[0]}</p>
                                                    <p>{tournament.time.split(" ")[1]}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        ))
                    )}
                    {notFoundTournament && (
                        <div className="d-flex justify-content-center align-items-center no-data-found">
                            <p>No tournaments found</p>
                        </div>
                    )}
                </div>
            </div>
            {/*Model*/}
            <div className="modal fade subscribe-modal" id="showSuccess" tabIndex="-1"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog subscribe-modal-dialog">
                    <div className="modal-content subscribe-modal-content">
                        <div className="modal-body subscribe-modal-body">
                            <div className="subscribe-modal-body-back-img notification-back-img">
                                <img className="rewards-modal-icon" src={rewardsImg} alt="rewards-success" />
                            </div>
                            <h6 className="subscribe-info-heading-modal">Congratulations</h6>
                            <p className="subscribe-heading-modal">{tournamentCheckIn}</p>
                        </div>
                    </div>
                </div>
            </div>
            <button style={{ display: 'none' }} ref={showSuccessModal} data-bs-toggle="modal" data-bs-target="#showSuccess">modal box</button>
        </section>
    );
}

export default TournamentPage;