import axiosClient from './axiosClient';
/**
 *
 * @param endPoint
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function requestGetHTTP(endPoint, params) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.get(endPoint, JSON.stringify(params))
        .then(response => {
            if (response.status === 200 && response.data.success === true) {
                return response.data;
            } else {
                return false;
            }
        })
        .catch(error => {
            console.error('response from '+endPoint, error);
        });
}

/**
 *
 * @param endPoint
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function requestPostHTTP(endPoint, params = {}) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post(endPoint, JSON.stringify(params))
        .then(response => {
            if (response.status === 200 && response.data.success === true) {
                return response.data;
            } else {
                return false;
            }
        })
        .catch(error => {
            console.error('response from '+endPoint, error);
        });
}
